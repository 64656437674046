export const environment = {
  production: false,
  title: 'SparkTime',
  facebookAppId: '1733071243846852',
  facebookApiVersion: 'v19.0',
  facebookSdk: 'https://connect.facebook.net/en_US/sdk.js',
  authApiUrl: 'https://dev-identity-api.pfacontab.ro',
  apiUrl: 'https://dev-sparktime-api.relaxedcoder.com',
  //apiUrl: 'https://localhost:44357',
};
